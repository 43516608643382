<template>
  <cw-page
    icon="check"
    class="cw-application-sent"
  >
    <template #hero-title>
      <translate>
        Thank you for your loan application!
      </translate>
    </template>

    <v-row>
      <v-col class="application-sent__text--wrapper" cols="2" md="6">
        <div class="mt-4 text-caption">
          <translate>
            We are now waiting for offers. You will be redirected to offers page soon...
          </translate>
        </div>
        <div id="takingTooLong" class="mt-4 text-caption" style="visibility: hidden">
          <translate>
            Looks like this taking longer than expected. You can try refreshing the page.
          </translate>
        </div>
      </v-col>
      <v-col class="application-sent__text--wrapper" cols="2" md="6">
        <div class="mt-4 pl-8 text-caption">
          <template>
            <div class="lds-spinner">
              <div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/>
              <div class="lds-spinner-content">
                {{ startTime }}
              </div>
            </div>
          </template>
        </div>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CwApplicationSent',

  data: () => ({
    template: 'cw-brokered-loan-offer',
    hasOffers: false,
    startTime: 59,
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      hash: 'application/hashId',
    }),
  },

  created() {
    // placeholder
  },

  mounted() {
    this.startPolling();
    const sleep = time => new Promise(resolve => setTimeout(resolve, time));
    const poll = async (time) => {
      const el = document.getElementById('submit');
      if (el) {
        el.style.display = 'none';
        return;
      }
      await sleep(time);
      await poll(time);
    };
    poll(10);
  },

  beforeDestroy() {
    clearTimeout(this.redirect);
  },

  methods: {
    ...mapActions({
      getApplication: 'application/getApplication',
      getOffers: 'application/getBrokeredOffers',
      startPolling() {
        const sleep = time => new Promise(resolve => setTimeout(resolve, time));
        const poll = async (time) => {
          if (this.startTime === 0) {
            // TODO: a minute has passed. what to do?
            document.getElementById('takingTooLong').style.visibility = 'visible';
            return;
          }

          await this.getApplication();
          if (this.initialData.currentPage && this.initialData.currentPage !== 'applicationSent') {
            this.$router.push({ name: 'changePage' }).catch(() => {});
            return;
          }
          await sleep(time);
          this.startTime -= 1;
          await poll(time);
        };
        poll(1000);
      },
    }),
  },
};
</script>
<style>
/* SPINNER */
.lds-spinner-content {
  padding-top: 30px;
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
}
.lds-spinner {
  color: #000000;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #000;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

<style scoped>

</style>
